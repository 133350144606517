/* eslint-disable */
import { ActionTypes } from "../../redux/actions";

const initailData = {
    singlposterslist: [],
    selectedPosters: [],
    selectPosterMulti: {
        cart: 1,
        amount: 3.99,
    },
    PreselectMultiPoster: false,
    is_login: "no",
    profiledata: {},
    accessToken: "",
    customerDetail : {},
    cardDetails: {},
    accountUrl: "my-books",
    myproducts: [],
    products: [],
    currencyList: [],
    productidObjects: {},
    checkCacheNews: "",
    geoComplyLocation: "",
    isSiteBlock: true,
    ipAddress: "",
    spoofingDetection:"",
    ComplyErrorMessage: "",
    UniqueBrowserId: "",
    LicenseErrorMsg: "",
    StopOverCalling: false,
    ReGeoLcTimeGet: "",
    CardDetailsStore: {},
    CreditRequestList: []
}

export const posterReducers = (state = initailData, action) => {
    switch(action.type) {
        case ActionTypes.GET_LICENSE_ERROR_MESSAGE :
            return { 
                ...state, 
                LicenseErrorMsg : action.payload, 
            };
        case ActionTypes.STOP_OVERCALLING_GEOLOCATION :
            return { 
                ...state, 
                StopOverCalling : action.payload, 
            };
        case ActionTypes.GET_CARD_DETAILS_STORE :
            return { 
                ...state, 
                CardDetailsStore : action.payload, 
                profiledata : {
                    ...state.profiledata,
                    card_available: true,
                },
            };
        case ActionTypes.DELETE_CARD_DETAILS_STORE :
            return { 
                ...state, 
                CardDetailsStore : action.payload, 
                profiledata: {
                    ...state.profiledata,
                    card_available: false
                }
            };
        case ActionTypes.GET_REGEOLC_TIME :
            return { 
                ...state, 
                ReGeoLcTimeGet : action.payload, 
            };
        case ActionTypes.GET_SIGNLE_POSTER :
            return { 
                ...state, 
                singlposterslist : action.payload, 
            };
        case ActionTypes.GET_UNIQUE_BROWSER_ID :
            return { 
                ...state, 
                UniqueBrowserId : action.payload, 
            };
        case ActionTypes.GET_GEO_COMPLY_LOCATION :
            return {
                ...state,
                geoComplyLocation : action.payload,
            };
        case ActionTypes.GEOCOMPLY_ISSUE_MESSAGE :
            return {
                ...state,
                ComplyErrorMessage : action.payload,
            }
        case ActionTypes.IS_SITE_IS_BLOCK :
            return {
                ...state,
                isSiteBlock : action.payload,
            }
        case ActionTypes.SPOOFING_DETECTION :
            return {
                ...state,
                spoofingDetection : action.payload,
            }
        case ActionTypes.GET_IP_ADDRESS : 
            return {
                ...state,
                ipAddress: action.payload
            }
        case ActionTypes.ADD_TO_CART :
            return {
                ...state,
                selectedPosters : action.payload,
            }
        case ActionTypes.GET_PRODUCT_LIST :
            return {
                ...state,
                products : action.payload
            }
        case ActionTypes.GET_CURRENCY_LIST :
            return {
                ...state,
                currencyList : action.payload
            }
        case ActionTypes.PRESELECT_MULTI_POSTER :
            return {
                ...state,
                PreselectMultiPoster : action.payload
            }
        case ActionTypes.SELECT_POSTER_MULTI :
            return {
                ...state,
                selectPosterMulti : {
                    cart: action.payload.cart,
                    amount : action.payload.amount,
                },
            }
        case ActionTypes.CUSTOMER_DETAILS :
            return { 
                ...state, 
                customerDetail : action.payload, 
            };
        case ActionTypes.CARD_DETAILS :
            return { 
                ...state, 
                cardDetails : action.payload, 
            };
        case ActionTypes.IS_LOGIN :
            return { 
                ...state, 
                is_login : action.payload, 
            };
        case ActionTypes.ACCOUNT_NAVIGATE :
            return {
                ...state,
                accountUrl: action.payload,
            };
        case ActionTypes.GET_USER :
            return { 
                ...state, 
                profiledata: action.payload, 
            };
        case ActionTypes.GET_MY_PRODUCT :
            return { 
                ...state, 
                myproducts: action.payload.reverse(), 
            };
        case ActionTypes.ADD_MY_PRODUCT :
            const products = [...state.myproducts];
            products.unshift(action.payload);
            return { 
                ...state, 
                myproducts: products, 
            };
        case ActionTypes.GET_PRODUCT_ID_WISE :
            const productlists = [...action.payload];
            let arrayproducts = {};
            productlists && productlists.map((elm) => {
                arrayproducts[elm.id] = elm;
            });

            return {
                ...state,
                productidObjects : arrayproducts
            };
        case ActionTypes.UPDATE_USER :
            return { 
                ...state, 
                profiledata: action.payload, 
            };
        case ActionTypes.GET_CATEGORY : 
            return {
                ...state,
                categorylist: action.payload,
            }
        case ActionTypes.UPDATE_PROFILE_PIC : 
            const OldProfile = state.profiledata;
            OldProfile.user_avatar_path = action.payload;

            return {
                ...state,
                profiledata : OldProfile,
            }
        case ActionTypes.ACCESS_TOKEN :
            return { 
                ...state, 
                accessToken : action.payload, 
            };
        case ActionTypes.GET_CREDIT_REQUEST_LIST :
            return { 
                ...state, 
                CreditRequestList : action.payload, 
            };
        case  ActionTypes.SET_LOGOUT_USER :
            return {
                ...state, 
                singlposterslist: [],
                selectedPosters: [],
                selectPosterMulti: {
                    cart: 1,
                    amount: 3.99,
                },
                PreselectMultiPoster: false,
                is_login: "no",
                profiledata: {},
                accessToken: "",
                customerDetail : {},
                cardDetails: {},
                accountUrl: "my-books",
                myproducts: [],
                products: [],
                productidObjects: {},
                checkCacheNewsz: "",
                CardDetailsStore: {},
                isSiteBlock: true,
            };
        case  ActionTypes.SET_CLEAR_REDUX_FLOW :
            return initailData;
        default:
            return state;
    }
}