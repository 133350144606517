import React, { useState } from "react";
import EmailCheckIcon from "../../assets/img/email-payment.png";
import CryptoImg from "../../assets/img/Crypto.png";
import BankingIcon from "../../assets/img/bank-payment.png";
import { Button, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import SucessIcon from "../../assets/img/success.svg";
import { toast } from "react-toastify";
import {
  VERIFY_ACCOUNT_DETAILS,
  PROFILE_UPDATE_API_URL,
  GET_CRYPTO_CURRENCY,
  POST_CRYPTO_KYC_URL
} from "../Shared/constant";
import { updateUser, CallLogoutUser, getUser } from "../../redux/actions";
import Verify from "../../assets/img/verify.png";
import InfoIcon from "../../assets/img/information-button.png";
import { Country, State, City }  from 'country-state-city';
import Select from 'react-select';
import { iso2ToIso3Map } from "./Iso";
import { type } from "@testing-library/user-event/dist/type";
import { getCurrecnyList } from "../../redux/actions";



const Withdrawalmethods = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [gender, setGender] = useState({
    key:1,value:"male",label:"Male"
  });
  const [gendererror, setGenderError] = useState("");

  const [ errorCountry, setErrorCountry ] = useState("");
  const [ errorCountry2, setErrorCountry2 ] = useState("");

  const { profiledata, accessToken,currencyList } = useSelector(
    (state) => state.allReducers
  );
  const [ SelectCountry, setSelectCountry ] = useState({
    key: 232,
    label: "United States",
    value: "US",
    iso3: "USA"

});
  const [ SelectCountry2, setSelectCountry2 ] = useState({
    key: 232,
    label: "United States",
    value: "US",
    iso3: "USA"

});
const options = Country.getAllCountries().map((guest, index) => {
  // console.log("guest",guest)
  return {
     label: guest.name,
     value: guest.isoCode,
     key: index,
     iso3: iso2ToIso3Map[guest.isoCode] || "N/A", // Use mapping to set iso3

  }
});
const optionsformale = [
  {key:1,value:"male",label:"Male"},
  {key:2,value:"female",label:"Female"},
] 


const SelectCountryChange = (data_) => {
  if(data_?.value !== "") {
      setSelectCountry(data_);
      // console.log("data_",data_)
      setErrorCountry("");
  } else {
      setSelectCountry("");
      setErrorCountry("Please select country of birth");
  }
};
const onSelectGender = (data_) => {
  if(data_?.value !== "") {
      setGender(data_);
      // console.log("data_",data_)
      setGenderError("");
  } else {
    setGender("");
      setGenderError("Please select gender");
  }
};
const SelectCountryChange2 = (data_) => {
  if(data_?.value !== "") {
      setSelectCountry2(data_);
      setErrorCountry2("");
  } else {
      setSelectCountry2("");
      setErrorCountry2("Please select nationality");
  }
};
const customStyles = {
  control: base => ({
    ...base,
    height: 40,
    minHeight: 40,
    borderColor: "#e0e0e5"
  })
};

  const getDefaultMethod = () => {
    switch (profiledata?.payment_method) {
      case "CRYPTO":
        return "Crypto";
      case "ACH":
        return "Banking";
      case "CHEQUE":
        return "Mail Check";
      default:
        break; // Default to Banking if no method matches
    }
  };

  //   const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedMethod, setSelectedMethod] = useState(getDefaultMethod());

  const [RoutingNumber, setRoutingNumber] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [bankname, setBankName] = useState("");
  const [cryptoaddress, setCryptoAddress] = useState("");
  const [BillingAddressOne, setBillingAddressOne] = useState("");
  const [BillingAddressTwo, setBillingAddressTwo] = useState("");
  const [errorBillingAddressOne, setErrorBillingAddressOne] = useState("");
  const [errorBillingAddressTwo, setErrorBillingAddressTwo] = useState("");
  const [errorCryptoAddress, setErrorCryptoAddress] = useState("");
  const [banknameerror, setBankNameError] = useState("");
  const [accountnameerror, setAccountNameError] = useState("");
  const [routingnumbererror, setRoutingNumberError] = useState("");
  const [StoreSameAs, setStoreSameAs] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [emailCheckValid, setEmailCheckValid] = useState(
    profiledata?.is_email_check === null ||
      profiledata?.is_email_check === false
      ? false
      : profiledata?.is_email_check
  );

  useEffect(() => {
    setRoutingNumber(
      profiledata?.routing_number === null || profiledata?.routing_number === ""
        ? ""
        : profiledata?.routing_number
    );
    setAccountNumber(
      profiledata?.account_number === null || profiledata?.account_number === ""
        ? ""
        : profiledata?.account_number
    );
    setBankName(
      profiledata?.bank_name === null || profiledata?.bank_name === ""
        ? ""
        : profiledata?.bank_name
    );
    setCryptoAddress(
      profiledata?.wallet_address === null || profiledata?.wallet_address === ""
        ? ""
        : profiledata?.wallet_address
    );
    setBillingAddressOne(
      profiledata?.billing_address1 === null ||
        profiledata?.billing_address1 === ""
        ? ""
        : profiledata?.billing_address1
    );
    setBillingAddressTwo(
      profiledata?.billing_address2 === null ||
        profiledata?.billing_address2 === ""
        ? ""
        : profiledata?.billing_address2
    );
  }, [profiledata]);
  const checkSame = (e) => {
    setStoreSameAs(e.target.checked);
    if (e.target.checked) {
      setBillingAddressTwo(BillingAddressOne);
      setErrorBillingAddressTwo("");
    }
  };

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
    setErrorBillingAddressTwo("");
    setErrorBillingAddressOne("");
    setErrorCryptoAddress("");
    setBankNameError("");
    setAccountNameError("");
    setRoutingNumberError("");
    setSelectedItem(null)
  };

  const EmptySpaceFieldValid = (e) => {
    if (e.target.value.length === 0 && e.which === 32) {
      return e.preventDefault();
    }
  };
  const SaveData = (type) => {
    if (type === "Mail Check") {
      if (BillingAddressOne === "" || BillingAddressTwo === "") {
        if (BillingAddressOne === "") {
          setErrorBillingAddressOne("Billing Address 1 cannot be empty");
        }
        if (BillingAddressTwo === "") {
          setErrorBillingAddressTwo("Billing Address 2 cannot be empty");
        }
        return null;
      } else {
        setLoader(true);
        const jsonData = JSON.stringify({
          first_name: profiledata?.first_name,
          last_name: profiledata?.last_name,
          phone: profiledata?.phone,
          routing_number: profiledata?.routing_number,
          account_number: profiledata?.account_number,
          billing_address1: BillingAddressOne,
          billing_address2: BillingAddressTwo,
          is_email_check: true,
          bank_name: profiledata?.bank_name,
          wallet_address: profiledata?.wallet_address,
          payment_method: "CHEQUE",
        });
        window?.axios
          ?.post(PROFILE_UPDATE_API_URL, jsonData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + accessToken,
            },
          })
          .then(function (result) {
            dispatch(updateUser(result?.data?.data));
            setErrorBillingAddressOne("");
            setErrorBillingAddressTwo("");
            toast.success(result.data.msg);
            setLoader(false);
          })
          .catch(function (result) {
            toast.error(result.response.data.error);
            if (result?.response?.status === 403) {
              dispatch(CallLogoutUser());
              localStorage.removeItem("access_tokens");
            }
            setLoader(false);
          });
      }
    } else if (type === "Banking") {
      if (bankname === "" || RoutingNumber === "" || AccountNumber === "") {
        if (bankname === "") {
          setBankNameError("Bank Name cannot be empty");
        }
        if (AccountNumber === "") {
          setAccountNameError("Account Name cannot be empty");
        }
        if (RoutingNumber === "") {
          setRoutingNumberError("Routing Number cannot be empty");
        }
        return null;
      } else {
        setLoader(true);
        const jsonData = JSON.stringify({
          first_name: profiledata?.first_name,
          last_name: profiledata?.last_name,
          phone: profiledata?.phone,
          routing_number: RoutingNumber.trim(),
          account_number: AccountNumber.trim(),
          billing_address1: profiledata?.billing_address1,
          billing_address2: profiledata?.billing_address2,
          is_email_check: emailCheckValid,
          bank_name: bankname.trim(),
          wallet_address: profiledata?.wallet_address,
          payment_method: "ACH",
        });
        window?.axios
          ?.post(PROFILE_UPDATE_API_URL, jsonData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + accessToken,
            },
          })
          .then(function (result) {
            dispatch(updateUser(result?.data?.data));
            setErrorBillingAddressOne("");
            setErrorBillingAddressTwo("");
            toast.success(result.data.msg);
            setLoader(false);
          })
          .catch(function (result) {
            toast.error(result.response.data.error);
            if (result?.response?.status === 403) {
              dispatch(CallLogoutUser());
              localStorage.removeItem("access_tokens");
            }
            setLoader(false);
          });
      }
    } else if (type === "Crypto") {
      if (cryptoaddress === "") {
        if (cryptoaddress === "") {
          setErrorCryptoAddress("Crypto Address cannot be empty");
        }
        return null;
      } else {
        setLoader(true);
        const jsonData = JSON.stringify({
          first_name: profiledata?.first_name,
          last_name: profiledata?.last_name,
          phone: profiledata?.phone,
          routing_number: profiledata?.routing_number,
          account_number: profiledata?.account_number,
          billing_address1: profiledata?.billing_address1,
          billing_address2: profiledata?.billing_address2,
          is_email_check: emailCheckValid,
          bank_name: profiledata?.bank_name,
          wallet_address: cryptoaddress,
          payment_method: "CRYPTO",
        });
        window?.axios
          ?.post(PROFILE_UPDATE_API_URL, jsonData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + accessToken,
            },
          })
          .then(function (result) {
            dispatch(updateUser(result?.data?.data));
            setErrorBillingAddressOne("");
            setErrorBillingAddressTwo("");
            toast.success(result.data.msg);
            setLoader(false);
          })
          .catch(function (result) {
            toast.error(result.response.data.error);
            if (result?.response?.status === 403) {
              dispatch(CallLogoutUser());
              localStorage.removeItem("access_tokens");
            }
            setLoader(false);
          });
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const AccountVerificatataion = () => {
    setLoading(true);
    window?.axios
      ?.get(VERIFY_ACCOUNT_DETAILS, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(function (result) {
        if (result?.data?.data?.status === true) {
          toast.success(result?.data?.msg);
          dispatch(
            getUser({
              ...profiledata,
              paynote_verified: true,
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (result) {
        // toast.error(result?.response?.data?.messages);
        setLoading(false);
        const errorMessage = result?.response?.data?.messages;

        if (Array.isArray(errorMessage)) {
          errorMessage?.forEach((msg) => {
            toast?.error(msg);
          });
        } else if (typeof errorMessage === "string") {
          toast.error(errorMessage);
        }
        if (result?.response?.status === 403) {
          dispatch(CallLogoutUser());
          localStorage.removeItem("access_tokens");
        }
      });
  };
  const handleNumericInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

const [showkycdesign,setShowKycDesign] = useState(false)
const [load,setLoad] = useState(false)


  const KycVerify = () =>{
    if(SelectCountry !== ""&&SelectCountry2!=="" && gender !==""){
      setLoad(true)
     
 const payloadforKyc = {
      country_of_birth: SelectCountry?.iso3,
      nationality: SelectCountry2?.iso3,
      gender: gender?.label==="Female"? "F":"M",
      country:iso2ToIso3Map[profiledata?.country] || "N/A",
}
window?.axios
?.post(POST_CRYPTO_KYC_URL, payloadforKyc, {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + accessToken,
  },
})
.then(function (result) {
  toast.success(result.data.msg);
  dispatch(
    getUser({
      ...profiledata,
      crypto_verified: true,
    })
  );
  getCurrencyData()
  setGenderError("")
  setLoad(false);
  setErrorCountry("")
  setErrorCountry2("")
})
.catch(function (result) {
  toast.error(result.response.data.error);
  if (result?.response?.status === 403) {
    dispatch(CallLogoutUser());
    localStorage.removeItem("access_tokens");
  }
  setLoad(false);
});

    }else{
        if(gender===""){
        setGenderError("Gender cannot be empty.")
        }else{
        setGenderError("")
        } 

      if(SelectCountry === "") {
        setErrorCountry("Please select country of birth.");
    } else {
        setErrorCountry("");
    }
    if(SelectCountry2 === "") {
      setErrorCountry2("Please select nationality.");
  } else {
      setErrorCountry2("");
  }


    }
  }
  const [cdata,setCData] = useState([])
  const getCurrencyData = () =>{
    window?.axios
    ?.get(GET_CRYPTO_CURRENCY, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
    .then(function (result) {
      // dispatch(updateUser(result?.data?.data));
      setCData(result?.data?.data?.result)
      dispatch(getCurrecnyList(result?.data?.data?.result))
    })
    .catch(function (result) {
      // toast.error(result.response.data.error);
      if (result?.response?.status === 403) {
        dispatch(CallLogoutUser());
        localStorage.removeItem("access_tokens");
      }
      setLoader(false);
    });
  }
  useEffect(() => {
    getCurrencyData()
  }, []);
  const [selectedItemError,setSelectedItemError] = useState("")
  const SelectCurrency = (data) =>{
    setSelectedItemError("")
    setSelectedItem(data)
  }
  const CallKnowYourKycApi = () => {
    if (selectedItem && selectedItem.id) {
      console.log('Selected Item:', selectedItem?.network);

      const jsonData = JSON.stringify({
        network:selectedItem?.network,
        wallet_address: cryptoaddress,
      });

      setSelectedItemError(''); 
    } else {
      setSelectedItemError('Please select a currency before saving.');
    }
  };

// console.log("selectedItem",selectedItem?.id)

  return (
    <>
      <div className="payment-options">
        <h4>Withdrawal Methods :</h4>
        <div className="option_wrapper">
          <div
            className={`option-box`}
            onClick={() => handleMethodChange("Mail Check")}
          >
            <label className={`option`}>
              <input
                type="checkbox"
                checked={selectedMethod === "Mail Check"}
                id="Withdroal_checkbox"
              />
              <div
                className={`Name_wrapper 
          ${selectedMethod === "Mail Check" ? "selected" : ""}
          `}
              >
                <img
                  src={EmailCheckIcon}
                  alt="Mail Check"
                  className="option-img"
                />
                <span className="option-name">
                  Mailed Cheque
                  {selectedMethod === "Mail Check" ? <p>(Primary)</p> : null}
                </span>
              </div>
            </label>
          </div>

          <div
            className={`option-box`}
            onClick={() => handleMethodChange("Banking")}
          >
            <label className={`option`}>
              <input
                type="checkbox"
                checked={selectedMethod === "Banking"}
                id="Withdroal_checkbox"
              />
              <div
                className={`Name_wrapper  ${
                  selectedMethod === "Banking" ? "selected" : ""
                } `}
              >
                <img src={BankingIcon} alt="Banking" className="option-img" />
                <span className="option-name">
                  Banking
                  {selectedMethod === "Banking" ? <p>(Primary)</p> : null}
                </span>
              </div>
            </label>
          </div>

          <div
            className={`option-box`}
            onClick={() => handleMethodChange("Crypto")}
          >
            <label className={`option`}>
              <input
                type="checkbox"
                checked={selectedMethod === "Crypto"}
                id="Withdroal_checkbox"
              />
              <div
                className={`Name_wrapper ${
                  selectedMethod === "Crypto" ? "selected" : ""
                }`}
              >
                <img src={CryptoImg} alt="Crypto" className="option-img" />
                <span className="option-name">
                  Crypto
                  {selectedMethod === "Crypto" ? <p>(Primary)</p> : null}
                </span>
              </div>
            </label>
          </div>
        </div>

        <div>
          {selectedMethod === "Mail Check" && (
            <div className="method-section">
              <h3>Mailed Cheque Section</h3>
              <div className="rowcustom">
                <div className="form-group">
                  <label>Billing Address 1 *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={BillingAddressOne}
                      onChange={(e) => {
                        setBillingAddressOne(e.target.value);
                        setErrorBillingAddressOne("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      placeholder="Enter Billing Address 1"
                    />
                    {BillingAddressOne !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {errorBillingAddressOne && (
                    <small className="text-danger">
                      {" "}
                      {errorBillingAddressOne}
                    </small>
                  )}
                </div>
              </div>
              <div className="rowcustom">
                <div className="termscondtaceptsection">
                  <div className="form-group-checkbox">
                    <input
                      type="checkbox"
                      id="StoreSameAs"
                      onChange={checkSame}
                      disabled={
                        BillingAddressOne == "" && BillingAddressTwo == ""
                          ? true
                          : false
                      }
                      checked={StoreSameAs}
                    />
                    <label htmlFor="StoreSameAs">
                      Billing address 1 Same Billing address 2{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div className="rowcustom">
                <div className="form-group">
                  <label>Billing Address 2 *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      disabled={StoreSameAs}
                      value={BillingAddressTwo}
                      onChange={(e) => {
                        setBillingAddressTwo(e.target.value);
                        setErrorBillingAddressTwo("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      placeholder="Enter Billing Address 2"
                    />
                    {BillingAddressTwo !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {errorBillingAddressTwo && (
                    <small className="text-danger">
                      {" "}
                      {errorBillingAddressTwo}
                    </small>
                  )}
                </div>
              </div>
              <div className="Withdraw_Btn_Wrapper">
                <Button
                  className="btn updteinfobtn"
                  disabled={loader}
                  onClick={() => SaveData("Mail Check")}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {selectedMethod === "Banking" && (
            <div className="method-section">
              <div className="Verify_wrapper">
                <h3>Banking Section</h3>
                {/* {profiledata?.paynote_verified ? (
                  <div className="verified">
                    <span>Verified</span>
                    <div>
                      <img alt="verify_img" src={Verify} />
                    </div>
                  </div>
                ) : (
                  <div className="verify">
                    <button disabled={loading} onClick={AccountVerificatataion}>
                      Verify
                    </button>
                    <div className="passwordnotewrapper2">
                      <ol>
                        <p>
                          To verify or attach your account details, please save
                          your account information first.
                        </p>
                      </ol>
                      <Image src={InfoIcon} alt="info icon" />
                    </div>
                  </div>
                )} */}
              </div>
              <div className="rowcustom">
                <div className="form-group">
                  <div className="Verify_wrapper">
                    <label>Bank Name *</label>
                  </div>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={bankname}
                      onChange={(e) => {
                        setBankName(e.target.value);
                        setBankNameError("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      placeholder="Enter Bank Name"
                    />
                    {bankname !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {banknameerror && (
                    <small className="text-danger">{banknameerror}</small>
                  )}
                </div>
              </div>
              <div className="rowcustom rowcustom-col-2">
                <div className="form-group">
                  <label>Routing Number *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={RoutingNumber}
                      onChange={(e) => {
                        setRoutingNumber(e.target.value);
                        setRoutingNumberError("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      onInput={handleNumericInput}
                      placeholder="Enter Routing Number"
                      autoComplete="off"
                    />
                    {RoutingNumber !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {routingnumbererror && (
                    <small className="text-danger">{routingnumbererror}</small>
                  )}
                </div>
                <div className="form-group">
                  <label>Account Number *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={AccountNumber}
                      onChange={(e) => {
                        setAccountNumber(e.target.value);
                        setAccountNameError("");
                      }}
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      onInput={handleNumericInput}
                      placeholder="Enter Account Number"
                      autoComplete="off"
                    />
                    {AccountNumber !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {accountnameerror && (
                    <small className="text-danger">{accountnameerror}</small>
                  )}
                </div>
              </div>
              <div className="Withdraw_Btn_Wrapper">
                <Button
                  className="btn updteinfobtn"
                  disabled={loader}
                  onClick={() => SaveData("Banking")}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {selectedMethod === "Crypto" && (
            <div className={`${!profiledata?.crypto_verified ? "method-section" :""}`}>
            <div className="Verify_wrapper">
              <h3> {!profiledata?.crypto_verified ? "Crypto KYC Verification":null }</h3>              

            </div>
              {/* <div className="rowcustom">
                <div className="form-group">
                  <label>Crypto Wallet Address *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={cryptoaddress}
                      onChange={(e) => {
                      setCryptoAddress(e.target.value)
                      setErrorCryptoAddress("")
                      }
                      }
                      onKeyDown={(e) => EmptySpaceFieldValid(e)}
                      placeholder="Enter Crypto Address"
                    />
                    {cryptoaddress !== "" && (
                      <img
                        src={SucessIcon}
                        className="errorsuccessicon"
                        alt={"icon"}
                      />
                    )}
                  </div>
                  {errorCryptoAddress && (
                    <small className="text-danger"> {errorCryptoAddress}</small>
                  )}
                </div>
              </div> */}
              {
                !profiledata?.crypto_verified ?(
              <>
              <div className="rowcustom rowcustom-col-2">
              <div className="form-group">
              <label>First Name *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={profiledata?.first_name}
                      disabled
                     
                    />
                   
                  </div>
              </div>
              <div className="form-group">
              <label>Last Name *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={profiledata?.last_name}
                      disabled
                     
                    />
                   
                  </div>
              </div>

              </div>
              <div className="rowcustom rowcustom-col-2">
              <div className="form-group">
              <label>Phone No *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={profiledata?.phone}
                      disabled
                     
                    />
                   
                  </div>
              </div>
              <div className="form-group">
              <label>Date Of Birth *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={profiledata?.dob}
                      disabled
                     
                    />
                   
                  </div>
              </div>

              </div>
              <div className="rowcustom rowcustom-col-2">
              <div className="form-group">
              <label>Country *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={profiledata?.country}
                      disabled
                     
                    />
                   
                  </div>
              </div>
              <div className="form-group">
              <label>PostCode *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={profiledata?.zip}
                      disabled
                     
                    />
                   
                  </div>
              </div>

              </div>
              <div className="rowcustom rowcustom-col-2">
              <div className="form-group">
              <label>Street Address *</label>
                  <div className="form-groupfiled">
                    <input
                      type="text"
                      className="form-control"
                      value={profiledata?.street_address}
                      disabled
                     
                    />
                   
                  </div>
              </div>
              <div className="form-group">
              <label>Gender *</label>

                  <div className="form-groupfiled">
                            <Select 
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={gender}
                                isClearable={true}
                                name="gender"
                                label={"gender"} 
                                options={optionsformale} 
                                value={gender}
                                onChange={onSelectGender}
                                styles={customStyles}
                            />
                            {gendererror !== "" && <div className="danger-color">{gendererror}</div>}
                        </div>
              </div>

              </div>
              <div className="rowcustom rowcustom-col-2">
              <div className="form-group">
                        <label>Country of Birth *</label>
                        <div className="form-groupfiled">
                            <Select 
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={SelectCountry}
                                isClearable={true}
                                name="isoCode"
                                label={"isoCode"} 
                                options={options} 
                                value={SelectCountry}
                                onChange={SelectCountryChange}
                                styles={customStyles}
                            />
                            {errorCountry !== "" && <div className="danger-color">{errorCountry}</div>}
                        </div>
                    </div>
              <div className="form-group">
              <label>Nationality *</label>
              <div className="form-groupfiled">
                            <Select 
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={SelectCountry2}
                                isClearable={true}
                                name="isoCode"
                                label={"isoCode"} 
                                options={options} 
                                value={SelectCountry2}
                                onChange={SelectCountryChange2}
                                styles={customStyles}
                            />
                            {errorCountry2 !== "" && <div className="danger-color">{errorCountry2}</div>}
                        </div>
              </div>

              </div>
              <div className="Withdraw_Btn_Wrapper">
                <Button
                  className="btn updteinfobtn"
                  disabled={load}
                  onClick={KycVerify}
                >
                  Submit Details
                </Button>
              </div>
              </>
              ):null


              }
              
              
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Withdrawalmethods;




















{/* <>


<div className="rowcustom">
  <div className="crypto-container">
   <h2 className="crypto-heading">We accept payments in the following networks.</h2>
   {selectedItemError && <div className="error-message">{selectedItemError}</div>}

   <div className={`crypto-grid ${currencyList?.length >= 9 ? 'scrollable-grid' : ''}`}>
    {currencyList?.map((item) => (
     <div 
     className={`crypto-card ${selectedItem?.id === item?.id ? 'selected' : ''}`}
      key={item?.id}
     onClick={() => SelectCurrency(item)}

     >
       <img
        className="crypto-logo"
        src={item?.payoutCurrency?.currency?.logo}
        alt={`${item.currency} logo`}
      />
        <div className="crypto-details">
        <span className="crypto-amount">{item?.currency}</span>
        <span className="crypto-network">{item?.network}</span>
      </div>
     </div>
      ))}
      </div>
    </div>

       <div className="form-group">
         <label>Crypto Wallet Address *</label>
         <div className="form-groupfiled">
           <input
             type="text"
             className="form-control"
             value={cryptoaddress}
             onChange={(e) => {
             setCryptoAddress(e.target.value)
             setErrorCryptoAddress("")
             }
             }
             onKeyDown={(e) => EmptySpaceFieldValid(e)}
             placeholder="Enter Crypto Address"
           />
           {cryptoaddress !== "" && (
             <img
               src={SucessIcon}
               className="errorsuccessicon"
               alt={"icon"}
             />
           )}
         </div>
         {errorCryptoAddress && (
           <small className="text-danger"> {errorCryptoAddress}</small>
         )}
       </div>
       <div className="Withdraw_Btn_Wrapper">
       <Button
         className="btn updteinfobtn">
         SAVE
       </Button>
        </div>
     </div>
     </> */}